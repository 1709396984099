import Vue from 'vue'

// axios
import axios from 'axios'

const axiosIns = axios.create({

  baseURL: 'https://api.bsgproperti.mrizkyff.com/',

})

Vue.prototype.$http = axiosIns

export default axiosIns
